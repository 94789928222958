<template>
		<div>
			<home-view />

			<home-footer />
		</div>
</template>

<script>

  export default {
    name: 'slimlineLayout',

    components: {
      HomeFooter: () => import('@/layouts/slimline/footer'),
      HomeView: () => import('@/layouts/slimline/view'),
    },
  }
</script>
